<template>
  <div class="top-info-new">
    <div class="welcome-tip">
      <span class="desc">欢迎访问武汉市前期物业管理电子招投标系统~</span>
      <span class="time">{{ currentTime }}</span>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'HeaderWelcome',
  data() {
    return {
      timer: null,
      times: new Date().getTime(),
    }
  },
  computed: {
    currentTime() {
      return moment(this.times).format('YYYY年MM月DD日 HH:mm:ss')
    },
  },
  mounted() {
    this.startTimer()
  },
  methods: {
    startTimer() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.times += 1000
      }, 1000)
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>
<style lang="less" scoped>
.top-info-new {
  width: 100%;
  min-width: 1200px;
  background: #dcdcdc;
  .welcome-tip {
    width: 1200px;
    height: 40px;
    line-height: 40px;
    margin: 0px auto;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    .desc {
      margin-right: 20px;
    }
  }
}
</style>
