<template>
  <div class="modify-container" v-title data-title="修改密码">
    <!-- 头部欢迎信息 -->
    <HeaderWelcome></HeaderWelcome>
    <div class="top-system">
      <div class="info-wrap">
        <span class="name">武汉市前期物业管理电子招投标系统</span>
        <div class="line-1px"></div>
        <span class="modify">修改密码</span>
      </div>
    </div>
    <div class="content-wrap">
      <div class="info-wrap">
        <div class="title">修改密码</div>

        <div class="form-wrap">
          <el-form
            :model="dataForm"
            ref="dataFormRef"
            label-width="135px"
            class="my-Form"
            :rules="rules"
          >
            <el-form-item prop="oldPassword" label="原密码：">
              <el-input
                v-model="dataForm.oldPassword"
                type="password"
                placeholder="输入原密码"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="newPassword" label="新密码：">
              <el-input
                v-model="dataForm.newPassword"
                type="password"
                placeholder="输入新密码"
                autocomplete="off"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="confirmNewPassword" label="确认新密码：">
              <el-popover
                popper-class="my-popper"
                placement="right"
                width="200"
                content="两次密码不一致"
                trigger="manual"
                v-model="showConfirmPassTip"
              >
                <el-input
                  slot="reference"
                  v-model="dataForm.confirmNewPassword"
                  type="password"
                  placeholder="确认新密码"
                  autocomplete="off"
                  @blur="checkSamePass"
                >
                </el-input>
              </el-popover>
            </el-form-item>
          </el-form>
          <el-button
            class="submit-btn"
            type="primary"
            @click="handleSubmitForm('dataFormRef')"
            >确认</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import HeaderWelcome from '@/components/common/header/vue/header-welcome.vue'

export default {
  name: 'Forgetpass',
  components: {
    HeaderWelcome,
  },
  data() {
    return {
      dataForm: {
        oldPassword: '', // 原密码
        newPassword: '', // 新密码
        confirmNewPassword: '', // 确认新密码
      },
      showConfirmPassTip: false,
      rules: {
        oldPassword: [{ required: true, message: '原密码不能为空' }],
        newPassword: [{ required: true, message: '新密码不能为空' }],
        confirmNewPassword: [{ required: true, message: '确认新密码不能为空' }],
      },
    }
  },
  beforeCreate() {
    Vue.prototype.$loading = {
      show: () => {
        this.loading = true
      },
      hide: () => {
        this.loading = false
        this.loadingText = ''
      },
      text: (loadingText) => {
        this.loadingText = loadingText
      },
    }
  },
  mounted() {},
  methods: {
    // 登录
    handleSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid && this.checkSamePass()) {
          this.showConfirmPassTip = false
          this.$router.push({ path: '/login' })
        }
      })
    },
    // 校验密码是否一致，并且提示
    checkSamePass() {
      if (this.dataForm.newPassword !== this.dataForm.confirmNewPassword) {
        this.showConfirmPassTip = true
        setTimeout(() => {
          this.showConfirmPassTip = false
        }, 3000)
        return false
      } else {
        this.showConfirmPassTip = false
        return true
      }
    },
  },
}
</script>

<style lang="less" scoped>
.modify-container {
  background: #fff;
  height: 100%;
  .top-system {
    width: 100%;
    min-width: 1200px;
    background: #fff;
    .info-wrap {
      width: 1200px;
      margin: 0px auto;
      height: 100px;
      display: flex;
      align-items: center;
      .name {
        color: #666666;
        font-size: 30px;
        font-weight: 400;
      }
      .line-1px {
        margin: 0px 30px;
        height: 50px;
        width: 1px;
        background-color: #707070;
      }
      .modify {
        font-size: 18px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .content-wrap {
    width: 100%;
    min-width: 1200px;
    height: calc(100% - 140px);
    background: #f8f9fd;
    padding-top: 62px;
    overflow: hidden;
    .info-wrap {
      width: 1200px;
      margin: 0px auto;
      height: 527px;
      overflow: hidden;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      .title {
        height: 69px;
        line-height: 69px;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        border-bottom: 1px solid #cccccc;
        box-sizing: border-box;
      }
      .form-wrap {
        width: 435px;
        margin: 0px auto;
        margin-top: 70px;
        text-align: center;
        ::v-deep .my-Form {
          .el-form-item {
            height: 42px;
            width: 435px;
            .el-form-item__label {
              color: #333333;
              font-size: 16px;
              font-weight: 400;
              padding-right: 20px;
              margin-bottom: 0px;
            }
            .el-form-item__content {
              input {
                height: 42px;
                font-size: 16px;
                padding: 0px 20px;
                &::placeholder {
                  color: #ccc;
                  font-size: 16px;
                }
              }
              .el-input__prefix {
                left: 20px;
                i {
                  font-size: 20px;
                }
              }
              .el-form-item__error {
                padding-top: 4px !important;
              }
            }
          }
        }
        .submit-btn {
          width: 300px;
          height: 50px;
          background-color: #4184f4;
          border-color: #4184f4;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          margin-top: 38px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.my-popper {
  margin-left: 45px !important;
  background-color: #f9e3e3;
  color: #ff0000;
  box-shadow: none !important;
  padding: 25px 30px 27px;
  font-size: 16px;
  text-align: center;
  .popper__arrow {
    left: -12px !important;
    border-width: 8px 12px;
    border-right-color: #f9e3e3 !important;
    &::after {
      border-right-color: #f9e3e3 !important;
    }
  }
}
</style>
